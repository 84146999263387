
const EMAIL_SETTING_TYPE = Object.freeze({
    OPERATION: 1,         
    FINANCIAL: 2,       
    COMMERCIAL: 3
});



function getApiUrl() {
    if (process.env.NODE_ENV === 'development') {
        return "http://localhost:5081";
    } else {
        // Return empty string in production
        return "";
    }
}

function getStringForBoolean(value) {

    if(value === true){
        return 'Sim'
    }
    else
    {
        return 'Não'
    }
}

function getEmailSettingsTypes() {
    var types = [
        { value: EMAIL_SETTING_TYPE.OPERATION, label:'Operacional' },
        { value: EMAIL_SETTING_TYPE.FINANCIAL, label:'Financeiro' },
        { value: EMAIL_SETTING_TYPE.COMMERCIAL, label:'Comercial' }
    ];

    return types;
}

function getEmailSetting(value) {
    const types = getEmailSettingsTypes();
    const type = types.find(t => t.value === value);
    return type ? type.label : null; // Return the label if found, otherwise null
}

export const utils = {
    
    getApiUrl,
    getStringForBoolean,
    getEmailSettingsTypes,
    getEmailSetting
};