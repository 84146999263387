import { utils } from '../helpers/utils';
import { apiHandler } from '../helpers/api-handler';

export const tenantEmailSettingService = {
    create,
    list,
    get,
    update,
};

function list(tenant_id) {
    return apiHandler.getApiRequest(utils.getApiUrl() + '/api/tenantemailsetting/list?tenantId=' + tenant_id)
        .then(response => {
            return response;
    });
}

function get(id) {
    return apiHandler.getApiRequest(utils.getApiUrl() + '/api/tenantemailsetting/get?id=' + id)
        .then(response => {
            return response;
    });
}

function create(email_setting) {
    return apiHandler.postApiRequest(utils.getApiUrl() + '/api/tenantemailsetting/create', email_setting)
        .then(response => {
            return response;
    });
}

function update(email_setting) {
    
    return apiHandler.postApiRequest(utils.getApiUrl() + '/api/tenantemailsetting/update', email_setting)
        .then(response => {
            return response;
    });
}
