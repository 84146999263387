<template>
    <div>
        <div class="row formSectionTitle" v-if="tenantModel">
            <div class="col-9">
                <q-icon color="white" name="dashboard" size="35px" />
                <span>{{ tenantModel.tenant.name }}</span>
            </div>
        </div>
        <div class="q-pa-md">
            <div style="text-align:right">
                <q-btn class="custom-button" label="Novo" no-caps @click="openModal(null)" />

            </div>
            <br>
            <q-table
            
            flat bordered
            class="my-sticky-header-table"
            dense
            :rows="rows"
            :columns="columns"
            :pagination="{ rowsPerPage: 0 }"
            >
            <template v-slot:body="props">
                <q-tr :props="props">
                <q-td key="email" :props="props">
                    {{ props.row.email }}
                </q-td>
                <q-td key="type" :props="props">
                    {{ $formatEmailSettingType(props.row.emailSettingType) }}
                </q-td>
                <q-td key="operations" :props="props">
                    <q-btn color="primary"
                            icon="edit"
                            size="15px"
                            flat
                            dense
                            @click="openModal(props.row)"
                            />
                   
                   
                </q-td>
                </q-tr>
            </template>
            </q-table>

        </div>
        <q-dialog v-model="open_modal">
            <dialog-wrapper title="Configuração de email">
                <q-form @submit="save" class="q-pa-md">
                    <div class="row">
                        <div class="col-12">
                            <q-input outlined 
                                    label="E-mail"
                                    v-model="selected_email_setting.email"
                                    :rules="[validateEmail]" 
                                    filled>
                                                        
                            </q-input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                        <q-option-group
                            v-model="selected_email_setting.emailSettingType"
                            :options="email_setting_types"
                            color="primary"
                            style="border: solid 1px;border-color:antiquewhite; padding:7px;"
                            inline
                        />
                        </div>
                    </div>
                    <br>
                    <div class="row items-center">
                        <div class="col-12" style="padding-top: 10px; text-align:center">
                            <q-btn color="blue" label="Salvar" type="submit" style="margin-right: 5px;" />
                            <q-btn color="grey" label="Fechar" @click="closeModal()" />
                        </div>
                    </div>
                </q-form>
            </dialog-wrapper>
        </q-dialog>
    </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue';
import { tenantEmailSettingService } from '../services/tenantemailsetting.service';
import { tenantService } from '../services/tenant.service';
import { useRoute } from 'vue-router';
import DialogWrapper from '../components/DialogWrapper.vue';
import {utils} from '../helpers/utils';
import { reactive } from 'vue';



const columns = [
  {
    name: 'email',
    label: 'E-mail',
    align: 'center',
    field: row => row.email,
    format: val => `${val}`,
    sortable: true
  },
  { name: 'type', label: 'Tipo', align: 'center',field: 'emailSettingType', sortable: true },
  { name: 'operations', label: '', field: '', sortable: true, align: "left" }
]

export default defineComponent({
    components : {DialogWrapper},
    setup() {
        // Route
        const route = useRoute();

         //Data
        const rows = ref([]);
        const open_modal = ref(false);
        const selected_email_setting = reactive({});
        const email_setting_types = ref([]);



        // Tenant
        const tenantModel = ref({
            tenant: {
                id: 0,
                name: null,
                cnpj: null,
                alias: null,
                active: true
            }
            
        });

       

        // Methods
        const getTenant = async () => {
            try {
                const response = await tenantService.get(route.params.tenantId);
                tenantModel.value = response;
               
            } catch (error) {
                console.error("Error fetching tenant data:", error);
            }
        };

        const  getTenantEmailSettingList = () => {
            tenantEmailSettingService.list(route.params.tenantId)
            .then((response) => {
                rows.value = response  
            });
        };

        const openModal = (email_setting) => {

            if(email_setting)
            {
                Object.assign(selected_email_setting,email_setting);
            }
            else
            {
                Object.assign(selected_email_setting, {
                        id: 0, 
                        email: null, 
                        emailSettingType: 1, 
                        tenantId: route.params.tenantId
                    });                    
            }
                             
            open_modal.value = true;
        }

        const validateEmail = (value) => {
            // eslint-disable-next-line no-useless-escape
            const pattern = /^(([^<>()\[\]\\.,;:\s@"']+(\.[^<>()\[\]\\.,;:\s@"']+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || 'Email inválido';
        } 
        
        const getEmailSettingTypes = () => {
            var types = utils.getEmailSettingsTypes();
            email_setting_types.value = types;
        }

        const closeModal = () => {
            open_modal.value = false;
        }

        const  save = () => {
              
            
            if (selected_email_setting.id > 0) {
                

                tenantEmailSettingService.update(selected_email_setting)
                .then(() => {
                    open_modal.value = false;
                    getTenantEmailSettingList();
                });
            }
            else
            {
                tenantEmailSettingService.create(selected_email_setting)
                .then(() => {
                    open_modal.value = false;
                    getTenantEmailSettingList();
                });
            }
            
        };


        onMounted(() => {
            getTenant();
            getTenantEmailSettingList();
            getEmailSettingTypes();
        });

        return { 
            tenantModel,
            columns,
            rows,
            openModal,
            open_modal,
            validateEmail,
            email_setting_types,
            selected_email_setting,
            closeModal,
            save
        
        };
    }
});
</script>
<style scoped>
.formSectionTitle {
    background-color: #91a8c7;
    text-align: left;
    padding-left: 50px;
    font-size: 20px;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
}

.formSectionTitle span {
    padding-left: 20px;
}
</style>